@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind&display=swap");
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  text-decoration: none; }

mark {
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

ul, ol {
  list-style: none; }

button {
  border: none;
  background: none;
  padding: 0; }

*, *::before, *::after {
  margin: 0;
  box-sizing: border-box; }

html {
  font-size: 62.5%; }

body {
  position: relative;
  font-size: 1.6rem;
  color: #333;
  min-width: 1260px;
  line-height: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-break: strict;
  overflow-wrap: break-word;
  word-wrap: break-word;
  font-family: Noto Sans JP,ヒラギノ角ゴ ProN W3,Hiragino Kaku Gothic ProN,游ゴシック,YuGothic,Yu Gothic,メイリオ,Meiryo,sans-serif; }
  @media screen and (max-width: max-width 1260px) {
    body {
      overflow: scroll; } }
  @media screen and (max-width: 750px) {
    body {
      overflow: auto;
      min-width: auto; } }
  @media screen and (max-width: 750px) {
    body {
      font-size: 16px;
      font-size: 4.26667vw; } }

img {
  max-width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast; }

a {
  color: #333;
  text-decoration: underline;
  word-wrap: break-word;
  transition: .5s ease; }
  a:hover {
    text-decoration: none; }

button:hover {
  cursor: pointer !important; }

address {
  font-style: normal; }

.l-container__main {
  width: 960px;
  margin: 0 auto; }
  @media screen and (max-width: 750px) {
    .l-container__main {
      width: 100%;
      margin-bottom: 0;
      padding: 0 4.26667vw; } }
  .l-container__main--2column {
    width: 960px;
    margin: 0 auto; }
    @media screen and (max-width: 750px) {
      .l-container__main--2column {
        width: 100%;
        padding: 0 4.26667vw; } }
    .l-container__main--2column__inner {
      padding: 0 20px;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 750px) {
        .l-container__main--2column__inner {
          display: block;
          width: 100%;
          padding: 0; } }

@media screen and (max-width: 750px) {
  .l-container__left {
    width: 100%; } }

.l-header {
  border-top: #004EA2 5px solid; }
  @media screen and (max-width: 750px) {
    .l-header {
      border-top: #004EA2 1.06667vw solid; } }
  .l-header__inner {
    padding: 8px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1260px; }
    @media screen and (max-width: 750px) {
      .l-header__inner {
        display: block;
        min-width: auto;
        padding: 0; } }
  .l-header__logo {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 750px) {
      .l-header__logo {
        margin: 2.66667vw 0 2.4vw 2.66667vw; } }
    .l-header__logo--right {
      width: 277px;
      display: block;
      -moz-display: block; }
      @media screen and (max-width: 750px) {
        .l-header__logo--right {
          width: 49.6vw;
          margin-left: 3.2vw; } }
      .l-header__logo--right img {
        -moz-vertival-align: bottom;
        vertical-align: bottom; }
        @media screen and (max-width: 750px) {
          .l-header__logo--right img {
            margin-top: 0;
            vertical-align: text-top; } }
    .l-header__logo--en img {
      width: 196px;
      margin-right: 25px; }
      @media screen and (max-width: 750px) {
        .l-header__logo--en img {
          width: 22.13333vw;
          margin-right: 2.13333vw; } }
    .l-header__logo--jp {
      margin: 0; }
      .l-header__logo--jp img {
        width: 507px;
        vertical-align: middle; }
        @media screen and (max-width: 750px) {
          .l-header__logo--jp img {
            width: 64.26667vw;
            vertical-align: baseline;
            margin-bottom: 0.26667vw; } }

.l-gnav--head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 750px) {
    .l-gnav--head {
      width: 100%;
      padding: 2.13333vw 4.26667vw;
      justify-content: center;
      background-color: #efefef; } }
  .l-gnav--head__list {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    justify-content: space-between; }
    @media screen and (max-width: 750px) {
      .l-gnav--head__list {
        font-size: 14px;
        font-size: 3.73333vw; } }
    .l-gnav--head__list__content {
      padding: 0 20px; }
      .l-gnav--head__list__content:first-of-type {
        padding-left: 0; }
      @media screen and (max-width: 750px) {
        .l-gnav--head__list__content {
          padding: 0 2.66667vw; }
          .l-gnav--head__list__content:nth-of-type(2n) {
            padding-left: 0; } }
      .l-gnav--head__list__content a {
        text-decoration: none;
        transition: 300ms ease; }
        @media screen and (max-width: 750px) {
          .l-gnav--head__list__content a {
            transition: none; } }
        .l-gnav--head__list__content a:hover {
          color: #004EA2; }
      .l-gnav--head__list__content--en {
        border-right: #e0e0e0 1px solid;
        border-left: #e0e0e0 1px solid; }
  .l-gnav--head__form {
    position: relative;
    margin-left: 20px; }
    @media screen and (max-width: 750px) {
      .l-gnav--head__form {
        margin-left: 3.2vw; } }
    .l-gnav--head__form__search {
      width: 180px;
      height: 30px;
      padding: 0 3rem 0 1rem;
      border-radius: 15px;
      border: #e0e0e0 1px solid;
      background-color: #f9f9f9;
      -webkit-appearance: none; }
      @media screen and (max-width: 750px) {
        .l-gnav--head__form__search {
          width: 43.2vw;
          padding: 0 8vw 0 2.66667vw; } }
    .l-gnav--head__form::-webkit-search-cancel-button {
      display: none; }
    .l-gnav--head__form__submit {
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 15px;
      width: 15px;
      background-color: #fff;
      background-image: url(../image/common/icon_search.svg);
      background-repeat: no-repeat;
      border: none;
      cursor: pointer !important; }
      @media screen and (max-width: 750px) {
        .l-gnav--head__form__submit {
          top: 1.06667vw; } }

.l-gnav--main {
  box-shadow: 0 5px 0 rgba(189, 189, 189, 0.1);
  border-bottom: #e0e0e0 1px solid;
  margin-bottom: 45px; }
  @media screen and (max-width: 750px) {
    .l-gnav--main {
      box-shadow: none;
      border: none;
      margin-bottom: 13.33333vw; } }
  .l-gnav--main__list {
    display: flex;
    justify-content: center;
    max-width: 960px;
    margin: 0 auto; }
    @media screen and (max-width: 750px) {
      .l-gnav--main__list {
        justify-content: flex-start;
        width: 100%;
        max-width: none;
        flex-wrap: wrap; } }
    .l-gnav--main__list__content {
      position: relative; }
      @media screen and (max-width: 750px) {
        .l-gnav--main__list__content {
          width: 50%;
          border-top: 1px solid #e0e0e0; }
          .l-gnav--main__list__content:first-child, .l-gnav--main__list__content:nth-child(2) {
            border-top: none; }
          .l-gnav--main__list__content:nth-child(2n+1):last-child {
            width: 100%; }
            .l-gnav--main__list__content:nth-child(2n+1):last-child a {
              width: 50%; }
              .l-gnav--main__list__content:nth-child(2n+1):last-child a::before {
                display: block;
                height: 4.26667vw;
                margin: auto;
                content: "";
                border-right: 1px solid #e0e0e0;
                position: absolute;
                right: 50%;
                top: 0;
                bottom: 0; }
          .l-gnav--main__list__content:nth-child(odd) a::before {
            display: block;
            height: 4.26667vw;
            margin: auto;
            content: "";
            border-right: 1px solid #e0e0e0;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0; } }
      .l-gnav--main__list__content img {
        margin-right: 16px;
        width: 36px;
        vertical-align: middle; }
        @media screen and (max-width: 750px) {
          .l-gnav--main__list__content img {
            margin-right: 3.2vw;
            width: 9.6vw; } }
      .l-gnav--main__list__content a {
        display: block;
        padding: 12px 0;
        min-width: 180px;
        text-align: center;
        text-decoration: none;
        transition: 300ms ease; }
        @media screen and (max-width: 750px) {
          .l-gnav--main__list__content a {
            transition: none; } }
        @media screen and (max-width: 750px) {
          .l-gnav--main__list__content a {
            padding: 4.53333vw 7.46667vw;
            text-align: left; } }
        .l-gnav--main__list__content a:hover {
          color: #fff;
          background-color: #004EA2; }

@media screen and (max-width: 750px) {
  .p-top .l-gnav--main {
    margin: -4.53333vw 0 13.33333vw; } }

.p-top .l-gnav--main__list {
  margin: 0 auto;
  padding-top: 3px; }
  @media screen and (max-width: 750px) {
    .p-top .l-gnav--main__list {
      padding-top: 0.26667vw;
      margin-top: 0; } }
  .p-top .l-gnav--main__list__content a {
    padding: 12px 0; }
    @media screen and (max-width: 750px) {
      .p-top .l-gnav--main__list__content a {
        padding: 4.53333vw 7.46667vw;
        padding-right: 0;
        text-align: left;
        min-width: 50%; } }

.l-footer {
  background-color: #060814;
  padding: 65px 0 0;
  color: #FFF;
  line-height: 1.7;
  margin-top: 80px; }
  @media screen and (max-width: 750px) {
    .l-footer {
      padding: 10.66667vw 0 0;
      margin-top: 17.06667vw; } }
  .l-footer__inner {
    width: 1131px;
    margin: 0 auto; }
    @media screen and (max-width: 750px) {
      .l-footer__inner {
        width: auto;
        padding: 4vw 4vw 0 4vw; } }
    .l-footer__inner__box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px; }
      @media screen and (max-width: 750px) {
        .l-footer__inner__box {
          display: block;
          width: auto;
          margin-bottom: 4.53333vw; } }
  .l-footer__list {
    display: flex;
    align-items: flex-start;
    font-size: 1.4rem;
    flex-wrap: wrap;
    justify-content: flex-end; }
    @media screen and (max-width: 750px) {
      .l-footer__list {
        display: block;
        flex-wrap: wrap;
        font-size: 12px;
        font-size: 3.2vw; } }
    .l-footer__list__content {
      padding: 0 20px;
      border-left: #14255B 1px solid;
      border-right: #14255B 1px solid; }
      @media screen and (max-width: 750px) {
        .l-footer__list__content {
          padding: 0;
          margin-bottom: 2.66667vw;
          font-size: 12px;
          font-size: 3.2vw;
          border: none; } }
      .l-footer__list__content a {
        color: #fff;
        text-decoration: none;
        transition: 300ms ease; }
        @media screen and (max-width: 750px) {
          .l-footer__list__content a {
            transition: none; } }
        .l-footer__list__content a:hover {
          opacity: 0.8; }
  @media screen and (max-width: 750px) {
    .l-footer__textarea {
      margin-bottom: 10.66667vw; } }
  .l-footer__textarea a[href^="tel:"] {
    color: #fff;
    text-decoration: none; }
  .l-footer__textarea__title {
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 12px; }
    @media screen and (max-width: 750px) {
      .l-footer__textarea__title {
        font-size: 16px;
        font-size: 4.26667vw;
        margin-bottom: 6.4vw; } }
  .l-footer__textarea__text {
    font-weight: 400; }
    .l-footer__textarea__text a {
      color: #fff;
      transition: 500ms ease;
      text-decoration: none; }
      .l-footer__textarea__text a:hover {
        opacity: 0.8; }
  .l-footer--copy {
    border-top: #14255B 1px solid; }
    .l-footer--copy__inner {
      width: 1131px;
      margin: 0 auto; }
      @media screen and (max-width: 750px) {
        .l-footer--copy__inner {
          width: auto;
          padding: 0 4vw; } }
    .l-footer--copy__text {
      text-align: right;
      padding: 19px 0;
      font-size: 1.4rem;
      font-family: 'Hind', sans-serif; }
      @media screen and (max-width: 750px) {
        .l-footer--copy__text {
          text-align: center;
          font-size: 12px;
          font-size: 3.2vw;
          padding: 5.33333vw 0; } }

.l-footer .menu-item {
  padding: 0 20px;
  border-left: #14255B 1px solid;
  border-right: #14255B 1px solid;
  margin-bottom: 20px; }
  @media screen and (max-width: 750px) {
    .l-footer .menu-item {
      padding: 0;
      margin-bottom: 2.66667vw;
      font-size: 12px;
      font-size: 3.2vw;
      border: none; } }
  .l-footer .menu-item a {
    color: #fff;
    text-decoration: none;
    transition: 300ms ease; }
    @media screen and (max-width: 750px) {
      .l-footer .menu-item a {
        transition: none; } }
    .l-footer .menu-item a:hover {
      opacity: 0.8; }
  .l-footer .menu-item::before {
    background: none;
    content: none;
    width: 0;
    height: 0; }

.c-btn {
  background-color: #004EA2;
  border: none;
  padding: 10px 50px;
  font-size: 1.6rem;
  border-radius: 4px;
  color: #fff;
  transition: .5s ease;
  margin: 0; }
  .c-btn:hover {
    cursor: pointer !important;
    opacity: 0.8; }
  .c-btn--support {
    display: inline-block;
    width: 280px;
    padding: 9px 0;
    font-weight: 700;
    text-decoration: none; }
    @media screen and (max-width: 750px) {
      .c-btn--support:first-child {
        margin-bottom: 8.53333vw; } }
  .c-btn--archives {
    display: inline-block;
    margin: 0 auto;
    padding: 9px 68px;
    font-weight: 700;
    text-decoration: none; }
    @media screen and (max-width: 750px) {
      .c-btn--archives {
        padding: 2.4vw 18.13333vw; } }
  .c-btn__link {
    text-decoration: none;
    color: #fff !important;
    font-weight: 700; }
    .c-btn__link:hover {
      cursor: pointer !important;
      opacity: 0.8; }
    .c-btn__link--small {
      font-size: 12px; }
      @media screen and (max-width: 750px) {
        .c-btn__link--small {
          font-size: 12px;
          font-size: 3.2vw; } }
  .c-btn--contact {
    width: 280px;
    padding: 13px 0;
    margin: 0 15px;
    font-weight: 700;
    border: #004EA2 1px solid;
    border-radius: 4px; }
    @media screen and (max-width: 750px) {
      .c-btn--contact {
        width: 74.66667vw;
        padding: 3.46667vw 0;
        margin-bottom: 8.53333vw; } }
  .c-btn__icon-word {
    position: relative; }
    .c-btn__icon-word::after {
      content: "";
      background-image: url(../image/common/icon_word.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: absolute;
      background-size: contain;
      width: 15px;
      height: 14px;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 14px; }
      @media screen and (max-width: 750px) {
        .c-btn__icon-word::after {
          right: 5.33333vw;
          width: 4vw;
          height: 3.73333vw; } }

.mw_wp_form_preview .c-btn--contact {
  margin: 0 15px; }
  .mw_wp_form_preview .c-btn--contact:first-child {
    color: #004EA2;
    background-color: #fff; }
  @media screen and (max-width: 750px) {
    .mw_wp_form_preview .c-btn--contact {
      margin: 0 auto; }
      .mw_wp_form_preview .c-btn--contact:nth-child(2) {
        margin: 8.53333vw auto 0; }
      .mw_wp_form_preview .c-btn--contact .p-form__submit {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center; } }

.c-list--js {
  display: none; }

.c-list--about {
  font-size: 16px; }
  @media screen and (max-width: 750px) {
    .c-list--about {
      font-size: 16px;
      font-size: 4.26667vw; } }
  .c-list--about__content {
    margin-bottom: 20px; }
    .c-list--about__content:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 750px) {
      .c-list--about__content {
        margin-bottom: 5.33333vw; } }

.c-list--support {
  display: flex;
  flex-wrap: wrap;
  column-count: 2; }
  .c-list--support__content {
    width: 450px;
    padding-right: 1em;
    margin-top: 14px; }
    .c-list--support__content:first-child {
      margin-top: 0; }
    .c-list--support__content:nth-child(2) {
      margin-top: 0; }
      @media screen and (max-width: 750px) {
        .c-list--support__content:nth-child(2) {
          margin-top: 2.66667vw; } }
    @media screen and (max-width: 750px) {
      .c-list--support__content {
        width: 100%;
        margin-top: 2.66667vw; } }

.c-list--category {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 750px) {
    .c-list--category {
      font-size: 16px;
      font-size: 4.26667vw; } }
  .c-list--category__content {
    margin: 0 20px 10px 0; }
    @media screen and (max-width: 750px) {
      .c-list--category__content {
        margin: 0 5.33333vw 2.66667vw 0; } }
  .c-list--category__link {
    padding: 4px 8px;
    text-decoration: none;
    transition: .5s ease;
    color: #fff;
    font-weight: 700;
    background-color: #004EA2; }
    @media print, screen and (min-width: 750px) {
      .c-list--category__link:hover {
        opacity: 0.8; } }
    @media screen and (max-width: 750px) {
      .c-list--category__link {
        padding: 1.06667vw 2.13333vw; } }

.c-list--tag {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px; }
  @media screen and (max-width: 750px) {
    .c-list--tag {
      font-size: 16px;
      font-size: 4.26667vw;
      padding-left: 5.33333vw; } }
  .c-list--tag__content {
    margin-right: 40px; }
    @media screen and (max-width: 750px) {
      .c-list--tag__content {
        margin-right: 8.53333vw; } }
  .c-list--tag__link {
    position: relative;
    display: inline-block;
    background-color: #fff; }
    .c-list--tag__link::before {
      content: '';
      background-image: url(../image/common/icon_tag.svg);
      width: 15px;
      height: 15px;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      display: inline-block;
      position: absolute;
      margin: auto;
      top: 2px;
      bottom: 0;
      left: -20px; }
      @media screen and (max-width: 750px) {
        .c-list--tag__link::before {
          width: 4vw;
          height: 4vw;
          top: 0.53333vw;
          left: -5.33333vw; } }

.c-list--gallery {
  display: flex;
  justify-content: space-between;
  margin-bottom: 81px; }
  @media screen and (max-width: 750px) {
    .c-list--gallery {
      overflow-x: scroll;
      width: 100vw;
      margin: 0 4.26667vw 10.93333vw -4.26667vw; } }
  .c-list--gallery__content {
    margin-right: 20px; }
    .c-list--gallery__content:last-child {
      margin-right: 0; }
    @media screen and (max-width: 750px) {
      .c-list--gallery__content {
        min-width: 57.6vw;
        margin: 0 5.33333vw; }
        .c-list--gallery__content:last-child {
          margin: 0 5.33333vw; } }
  .c-list--gallery__text {
    text-align: center; }

.c-list--blue {
  display: block;
  list-style-type: none;
  padding-left: 0;
  margin: 40px 0; }
  @media screen and (max-width: 750px) {
    .c-list--blue {
      margin-top: -2.66667vw; } }
  .c-list--blue__content {
    padding-left: 1.2em;
    position: relative; }
    @media screen and (max-width: 750px) {
      .c-list--blue__content {
        font-size: 16px;
        font-size: 4.26667vw; } }
    .c-list--blue__content:before {
      content: "・";
      color: #004EA2;
      position: absolute;
      width: 1em;
      top: 0;
      left: 0; }

.c-section {
  margin-bottom: 60px; }
  @media screen and (max-width: 750px) {
    .c-section {
      margin-bottom: 13.33333vw; } }
  .c-section--about {
    margin-bottom: 50px; }
    @media screen and (max-width: 750px) {
      .c-section--about {
        margin-bottom: 13.33333vw; } }
    .c-section--about__inner {
      padding: 0 25px; }
      @media screen and (max-width: 750px) {
        .c-section--about__inner {
          padding: 0; } }
  .c-section__inner {
    padding: 0 25px; }
    @media screen and (max-width: 750px) {
      .c-section__inner {
        padding: 0; } }
  .c-section--inter {
    margin-bottom: 90px; }
    @media screen and (max-width: 750px) {
      .c-section--inter {
        margin-bottom: 16.53333vw; } }
    .c-section--inter__inner {
      padding: 20px; }
      @media screen and (max-width: 750px) {
        .c-section--inter__inner {
          padding: 0; } }
  .c-section--library {
    margin-bottom: 50px; }
    @media screen and (max-width: 750px) {
      .c-section--library {
        margin-bottom: 10.66667vw; } }
  .c-section--contact {
    margin-bottom: 35px; }
    @media screen and (max-width: 750px) {
      .c-section--contact {
        margin-bottom: 3.73333vw; } }

.c-title {
  font-size: 2rem;
  color: #004EA2;
  font-weight: 700;
  border-left: 5px solid #004EA2;
  border-radius: 4px;
  padding: 9px 15px;
  background-color: #f9f9f9;
  margin-bottom: 30px;
  line-height: 1.6; }
  @media screen and (max-width: 750px) {
    .c-title {
      font-size: 20px;
      font-size: 5.33333vw;
      margin-bottom: 6.66667vw;
      padding: 1.33333vw 4vw; } }
  .c-title--regular {
    font-weight: 400;
    font-size: 2rem;
    color: #333;
    border-left: 5px solid #004EA2;
    border-radius: 4px;
    padding: 9px 15px;
    background-color: #f9f9f9;
    margin-bottom: 30px;
    text-decoration: none;
    line-height: 1.6; }
    @media screen and (max-width: 750px) {
      .c-title--regular {
        font-size: 20px;
        font-size: 5.33333vw;
        margin-bottom: 6.4vw;
        padding: 2.4vw 5.33333vw; } }
  .c-title--bold {
    font-weight: 700;
    font-size: 2rem;
    color: #333;
    border-left: 5px solid #004EA2;
    border-radius: 4px;
    padding: 9px 15px;
    background-color: #f9f9f9;
    margin-bottom: 30px;
    text-decoration: none;
    line-height: 1.6; }
    @media screen and (max-width: 750px) {
      .c-title--bold {
        font-size: 20px;
        font-size: 5.33333vw;
        margin-bottom: 6.4vw;
        padding: 1.33333vw 4vw; } }
  .c-title--black {
    color: #333;
    font-size: 1.8rem;
    font-weight: 700;
    background-color: #f9f9f9;
    padding: 7px 25px;
    border-radius: 4px;
    margin-bottom: 30px;
    line-height: 1.6; }
    @media screen and (max-width: 750px) {
      .c-title--black {
        font-size: 18px;
        font-size: 4.8vw;
        padding: 1.86667vw 0 1.86667vw 6.66667vw;
        margin-bottom: 6.4vw; } }
  .c-title--lined {
    padding: 3px 0 3px 10px;
    margin: 50px 0 25px;
    line-height: 1.6;
    border-left: 4px solid #004EA2;
    border-bottom: 1px solid #004EA2; }
    @media screen and (max-width: 750px) {
      .c-title--lined {
        font-size: 16px;
        font-size: 4.26667vw; } }
  .c-title--solid {
    font-size: 2.4rem;
    font-weight: 700; }
    @media screen and (max-width: 750px) {
      .c-title--solid {
        font-size: 24px;
        font-size: 6.4vw; } }
  .c-title--note {
    font-size: 1.4rem;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 1.4em; }
    @media screen and (max-width: 750px) {
      .c-title--note {
        font-size: 14px;
        font-size: 3.73333vw;
        position: static;
        display: block;
        text-align: right; } }
  .c-title--table-note {
    font-size: 1.4rem;
    font-weight: 400;
    text-align: right; }
    @media screen and (max-width: 750px) {
      .c-title--table-note {
        font-size: 14px;
        font-size: 3.73333vw;
        right: 4.53333vw; } }
  .c-title--sub {
    font-size: 1.6rem;
    color: #212121; }
    @media screen and (max-width: 750px) {
      .c-title--sub {
        font-size: 16px;
        font-size: 4.26667vw; } }
    .c-title--sub img {
      margin: 0 10px 0 0;
      vertical-align: baseline; }
    .c-title--sub a {
      text-decoration: none; }
      .c-title--sub a:hover {
        color: #004EA2; }
  .c-title--circle-arrow {
    padding-left: 20px;
    position: relative;
    display: inline-block; }
    @media screen and (max-width: 750px) {
      .c-title--circle-arrow {
        padding-left: 5.33333vw; } }
    .c-title--circle-arrow::before {
      content: "";
      background-image: url(../image/common/icon_arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
      display: inline-block;
      width: 14px;
      height: 14px;
      vertical-align: baseline;
      position: absolute;
      top: 10px;
      left: 0; }
      @media screen and (max-width: 750px) {
        .c-title--circle-arrow::before {
          width: 3.73333vw;
          height: 3.73333vw;
          top: 2.66667vw; } }
    .c-title--circle-arrow--middle {
      padding-left: 20px;
      position: relative;
      display: inline-block; }
      @media screen and (max-width: 750px) {
        .c-title--circle-arrow--middle {
          padding-left: 5.33333vw; } }
      .c-title--circle-arrow--middle::before {
        content: "";
        background-image: url(../image/common/icon_arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        display: inline-block;
        width: 14px;
        height: 14px;
        vertical-align: baseline;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto; }
        @media screen and (max-width: 750px) {
          .c-title--circle-arrow--middle::before {
            width: 3.73333vw;
            height: 3.73333vw; } }
    .c-title--circle-arrow--side {
      position: relative; }
      @media screen and (max-width: 750px) {
        .c-title--circle-arrow--side {
          padding-left: 5.33333vw; } }
      .c-title--circle-arrow--side::before {
        content: "";
        background-image: url(../image/common/icon_arrow.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        display: inline-block;
        width: 14px;
        height: 14px;
        position: absolute;
        margin: auto;
        top: 18px;
        left: 20px; }
        @media screen and (max-width: 750px) {
          .c-title--circle-arrow--side::before {
            width: 3.73333vw;
            height: 3.73333vw;
            top: 4.8vw;
            left: 5.33333vw; } }
  .c-title--circle-arrow--right {
    position: relative;
    padding-right: 30px; }
    @media screen and (max-width: 750px) {
      .c-title--circle-arrow--right {
        padding-right: 8vw; } }
    .c-title--circle-arrow--right::after {
      content: "";
      background-image: url(../image/common/icon_arrow.svg);
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: auto;
      position: absolute;
      right: 15px;
      bottom: 0;
      top: 0; }
      @media screen and (max-width: 750px) {
        .c-title--circle-arrow--right::after {
          width: 3.73333vw;
          height: 3.73333vw;
          right: 4vw; } }
  .c-title--light-arrow:after {
    content: "";
    position: absolute;
    background-image: url(../image/common/icon_accordion.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    display: inline-block;
    width: 20px;
    height: 5px;
    right: 20px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    transition: 500ms; }
    @media screen and (max-width: 750px) {
      .c-title--light-arrow:after {
        width: 3.2vw;
        height: 1.6vw;
        right: 5.33333vw;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: none; } }
  @media screen and (max-width: 750px) {
    .c-title--tel {
      text-decoration: none; } }
  @media print, screen and (min-width: 750px) {
    .c-title--tel {
      pointer-events: none;
      text-decoration: none; } }
  .c-title--pdf:after {
    content: "";
    background-image: url(../image/common/icon_pdf.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    width: 15px;
    height: 19px;
    display: inline-block;
    vertical-align: sub;
    margin-left: 10px; }
    @media screen and (max-width: 750px) {
      .c-title--pdf:after {
        width: 4vw;
        height: 5.06667vw;
        margin-left: 2.66667vw; } }
  .c-title--slash {
    color: #999;
    margin: 0 3px; }
  .c-title--category {
    font-size: 1.2rem;
    line-height: 1;
    color: #fff;
    background-color: #004ea2;
    padding: 4px 15px;
    display: inline-block; }
    @media screen and (max-width: 750px) {
      .c-title--category {
        width: 100%;
        font-size: 12px;
        font-size: 3.2vw;
        display: inline; } }
    .c-title--category--large__inner {
      font-size: 1.2rem;
      line-height: 1;
      color: #fff;
      background-color: #004ea2;
      padding: 4px 15px;
      margin-left: 20px;
      display: inline-block;
      text-decoration: none; }
      @media screen and (max-width: 750px) {
        .c-title--category--large__inner {
          width: 100%;
          margin-left: 8vw;
          font-size: 12px;
          font-size: 3.2vw;
          display: inline; } }
  .c-title--go {
    text-align: right; }
    @media screen and (max-width: 750px) {
      .c-title--go {
        margin-top: 2.66667vw; } }
    .c-title--go__link {
      text-decoration: underline;
      transition: .5s ease; }
      @media screen and (max-width: 750px) {
        .c-title--go__link {
          transition: none; } }
      .c-title--go__link:hover {
        text-decoration: none; }
  .c-title--new {
    color: red;
    font-size: 14px;
    vertical-align: baseline;
    padding-left: 10px; }
    @media screen and (max-width: 750px) {
      .c-title--new {
        font-size: 14px;
        font-size: 3.73333vw;
        padding-left: 2.66667vw; } }
  @media screen and (max-width: 750px) {
    .c-title--library {
      margin-bottom: 8.53333vw; } }
  .c-title--tag {
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    padding: 4px 15px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle; }
    .c-title--tag--red {
      background-color: #a20000; }
    .c-title--tag--gray {
      background-color: #999; }
    .c-title--tag--large {
      margin: 0 20px 5px 0; }
      .c-title--tag--large__inner {
        color: #fff;
        background-color: #004ea2;
        text-decoration: none;
        padding: 2px 15px;
        -webkit-transition: .5s ease;
        transition: .5s ease; }
        .c-title--tag--large__inner:hover {
          opacity: 0.8; }
  .c-title--blue {
    font-size: 1.6rem;
    font-weight: 700;
    color: #004EA2; }

.opened .c-title--light-arrow:after {
  transform: rotateX(180deg); }

.c-media--banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-self: center;
  margin-bottom: -30px; }
  @media screen and (max-width: 750px) {
    .c-media--banner {
      margin: 0 auto -4vw;
      width: 91.46667vw; } }
  .c-media--banner__content {
    width: 300px;
    height: 100px;
    margin: 0 30px 30px 0;
    text-align: center; }
    .c-media--banner__content:nth-child(3n) {
      margin-right: 0; }
      @media screen and (max-width: 750px) {
        .c-media--banner__content:nth-child(3n) {
          margin-right: 4vw; } }
    @media screen and (max-width: 750px) {
      .c-media--banner__content {
        width: 43.73333vw;
        height: 14.66667vw;
        margin: 0 4vw 4vw 0; }
        .c-media--banner__content:nth-child(even) {
          margin-right: 0; } }
    .c-media--banner__content img {
      width: 300px;
      height: 100px;
      object-fit: contain;
      transition: 300ms ease; }
      @media screen and (max-width: 750px) {
        .c-media--banner__content img {
          transition: none; } }
      .c-media--banner__content img:hover {
        opacity: 0.8; }
        @media screen and (max-width: 750px) {
          .c-media--banner__content img:hover {
            transition: none; } }
      @media screen and (max-width: 750px) {
        .c-media--banner__content img {
          width: 44vw;
          height: 14.66667vw; } }

.c-breadcrumb {
  width: 1132px;
  margin: 10px auto 75px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  @media screen and (max-width: 750px) {
    .c-breadcrumb {
      width: 100%;
      margin: 8vw 0 5.33333vw;
      padding: 0 4.26667vw;
      font-size: 16px;
      font-size: 4.26667vw; } }
  .c-breadcrumb__content::after {
    content: url(../image/common/icon_breadcrumb.svg);
    color: #e0e0e0;
    margin: 0 10px; }
  .c-breadcrumb__content:last-child::after {
    content: none; }

.p-about__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  @media screen and (max-width: 750px) {
    .p-about__list {
      justify-content: center;
      margin-top: 8vw; } }
  .p-about__list__content {
    margin-top: 40px; }
    @media screen and (max-width: 750px) {
      .p-about__list__content {
        margin-top: 5.33333vw; } }
    .p-about__list__content:first-of-type {
      margin-top: 0; }
    .p-about__list__content:nth-of-type(2) {
      margin-top: 0; }
      @media screen and (max-width: 750px) {
        .p-about__list__content:nth-of-type(2) {
          margin-top: 5.33333vw; } }
    .p-about__list__content__link {
      width: 460px;
      margin-bottom: 0;
      text-decoration: none;
      transition: .5s ease;
      height: 100%;
      align-items: center;
      display: flex; }
      @media screen and (max-width: 750px) {
        .p-about__list__content__link {
          width: 91.46667vw;
          margin-bottom: 0;
          transition: none; } }
      .p-about__list__content__link:hover {
        color: #004EA2; }

.p-about__table {
  width: 935px;
  font-size: 16px; }
  @media screen and (max-width: 750px) {
    .p-about__table {
      width: 100%;
      font-size: 16px;
      font-size: 4.26667vw; } }
  .p-about__table__head {
    width: 100px;
    text-align: left;
    font-weight: 700;
    padding-right: 1rem; }
    @media screen and (max-width: 750px) {
      .p-about__table__head {
        width: 50%;
        font-size: 16px;
        font-size: 4.26667vw;
        padding-right: 0; } }
  .p-about__table__date {
    width: 200px;
    text-align: left;
    font-weight: 700;
    padding-right: 1rem; }
    @media screen and (max-width: 750px) {
      .p-about__table__date {
        width: auto;
        padding-right: 0;
        font-size: 16px;
        font-size: 4.26667vw; } }
  @media screen and (max-width: 750px) {
    .p-about__table__line {
      display: flex;
      flex-direction: column; } }
  @media screen and (max-width: 750px) and (max-width: 750px) {
    .p-about__table__line {
      padding-bottom: 8vw; }
      .p-about__table__line:last-of-type {
        padding-bottom: 0; } }
  .p-about__table__text {
    padding-bottom: 30px; }
    @media screen and (max-width: 750px) {
      .p-about__table__text {
        padding-bottom: 0; } }
  .p-about__table--lined {
    width: 935px;
    font-size: 16px;
    text-align: left; }
    @media screen and (max-width: 750px) {
      .p-about__table--lined {
        width: 100%;
        font-size: 16px;
        font-size: 4.26667vw; } }
    .p-about__table--lined__line {
      border-bottom: 1px solid #e0e0e0; }
      @media screen and (max-width: 750px) {
        .p-about__table--lined__line {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-top: 6.66667vw; }
          .p-about__table--lined__line:first-of-type {
            padding-top: 0; } }
    .p-about__table--lined__head {
      width: 130px;
      padding: 15px 0;
      font-weight: 700;
      padding-right: 15px; }
      @media screen and (max-width: 750px) {
        .p-about__table--lined__head {
          width: auto;
          padding: 0 1.06667vw 0 0; } }
      .p-about__table--lined__head--3column {
        width: 300px;
        display: flex; }
        @media screen and (max-width: 750px) {
          .p-about__table--lined__head--3column {
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap; } }
      .p-about__table--lined__head__name {
        display: block;
        width: 150px; }
        @media screen and (max-width: 750px) {
          .p-about__table--lined__head__name {
            width: auto; } }
      .p-about__table--lined__head__position {
        display: block;
        width: 170px; }
        @media screen and (max-width: 750px) {
          .p-about__table--lined__head__position {
            width: fit-content; } }
    .p-about__table--lined__position {
      width: 170px;
      padding: 15px 0;
      padding-right: 15px;
      font-weight: 700; }
      @media screen and (max-width: 750px) {
        .p-about__table--lined__position {
          width: 50%;
          text-align: right;
          padding: 0 0 0 1.06667vw; } }
    .p-about__table--lined__text {
      padding-right: 15px; }
      @media screen and (max-width: 750px) {
        .p-about__table--lined__text {
          width: 100%;
          padding: 1.33333vw 0 4vw; } }

.p-about__image {
  width: 698px; }
  .p-about__image img {
    width: 100%; }
  @media screen and (max-width: 750px) {
    .p-about__image {
      width: 100%; } }

@media screen and (max-width: 750px) {
  .p-about__table--3column .p-about__table--lined__head {
    width: 50%;
    padding: 0 1.06667vw 0 0; } }

@media screen and (max-width: 750px) {
  .p-category__list {
    width: 100%; } }

.p-category__list__content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0; }
  @media screen and (max-width: 750px) {
    .p-category__list__content {
      display: block;
      margin-bottom: 6.4vw;
      padding-bottom: get_vww(20); } }

.p-category__list__image {
  width: 160px;
  padding-top: 5px; }
  @media screen and (max-width: 750px) {
    .p-category__list__image {
      width: 64vw;
      margin: 0 auto 4vw;
      padding-top: 0; } }

.p-category__list__textarea {
  width: 734px; }
  @media screen and (max-width: 750px) {
    .p-category__list__textarea {
      width: 100%; } }

.p-category__list__time {
  font-size: 14px;
  font-weight: 700; }
  @media screen and (max-width: 750px) {
    .p-category__list__time {
      font-size: 14px;
      font-size: 3.73333vw;
      margin: 0 auto; } }

@media screen and (max-width: 750px) {
  .p-category__list__title {
    margin-bottom: 4.26667vw; } }

.p-category__list__title__link {
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  color: #004EA2; }
  @media screen and (max-width: 750px) {
    .p-category__list__title__link {
      font-size: 16px;
      font-size: 4.26667vw;
      margin-bottom: 4.26667vw; } }

@media screen and (max-width: 750px) {
  .p-category__list__text {
    position: relative;
    margin-bottom: 4.26667vw; }
    .p-category__list__text .c-title--go {
      margin-top: 0; } }

@media screen and (max-width: 750px) {
  .p-contact__table {
    width: 100%; } }

@media screen and (max-width: 750px) {
  .p-contact__table__line {
    border-bottom: 1px solid #e0e0e0; }
    .p-contact__table__line:last-child {
      border-bottom: none; }
    .p-contact__table__line:first-child .p-contact__table__head {
      padding-top: 0; } }

.p-contact__table__line:last-child .error {
  margin-bottom: 15px; }
  @media screen and (max-width: 750px) {
    .p-contact__table__line:last-child .error {
      margin-bottom: 6.4vw; } }

.p-contact__table__head {
  font-weight: 400;
  width: 222px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0; }
  @media screen and (max-width: 750px) {
    .p-contact__table__head {
      width: 100%;
      padding: 6.4vw 0 4vw; } }

.p-contact__table__input {
  width: 698px;
  padding: 15px 0 15px 30px;
  position: relative; }
  @media screen and (max-width: 750px) {
    .p-contact__table__input {
      width: 100%;
      display: block;
      padding: 0 0 6.4vw; } }
  .p-contact__table__input .error:nth-of-type(2) {
    margin-bottom: 30px; }
    @media screen and (max-width: 750px) {
      .p-contact__table__input .error:nth-of-type(2) {
        margin-bottom: 6.4vw; } }
  .p-contact__table__input__text {
    display: inline-block;
    width: 2em;
    text-align: left; }

.p-contact__link {
  color: #004EA2;
  text-decoration: underline; }
  .p-contact__link:hover {
    text-decoration: none; }

.p-contact__text {
  margin-bottom: 36px; }

.p-contact__address {
  margin-top: 50px;
  padding-left: 25px; }
  @media screen and (max-width: 750px) {
    .p-contact__address {
      width: 100%;
      margin-top: 11.73333vw;
      padding-left: 0; } }
  .p-contact__address__name {
    font-size: 1.4rem;
    font-weight: 400; }
    @media screen and (max-width: 750px) {
      .p-contact__address__name {
        font-size: 16px;
        font-size: 4.26667vw; } }
  .p-contact__address__inner {
    font-size: 1.2rem; }
    @media screen and (max-width: 750px) {
      .p-contact__address__inner {
        font-size: 14px;
        font-size: 3.73333vw;
        width: 60%; } }

.p-contact__text-en {
  width: 6em; }
  @media screen and (max-width: 750px) {
    .p-contact__text-en {
      margin-top: 2.66667vw; } }

.mw_wp_form_confirm .p-contact__table__input__text {
  display: none; }

input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  box-sizing: border-box; }
  input[type="submit"]::-webkit-search-decoration,
  input[type="button"]::-webkit-search-decoration {
    display: none; }
  input[type="submit"]::focus,
  input[type="button"]::focus {
    outline-offset: -2px; }

.p-form__input {
  width: 160px;
  height: 40px;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 10px;
  font-size: 1.6rem;
  color: #333; }
  @media screen and (max-width: 750px) {
    .p-form__input {
      width: 100%;
      height: 10.66667vw;
      padding: 2.66667vw; } }
  .p-form__input::placeholder {
    color: #ccc;
    font-size: 1.6rem;
    vertical-align: middle;
    text-overflow: ellipsis; }
    @media screen and (max-width: 750px) {
      .p-form__input::placeholder {
        font-size: 16px;
        font-size: 4.26667vw; } }
  .p-form__input--name {
    margin: 0 30px 0 8px; }
    @media screen and (max-width: 750px) {
      .p-form__input--name {
        width: 32.53333vw;
        margin: 0 0.8vw 0 1.33333vw; }
        .p-form__input--name:last-of-type {
          margin-right: 0; } }
    @media screen and (max-width: 750px) {
      .p-form__input--name--en {
        width: 100%; } }
  .p-form__input--long {
    width: 668px; }
    @media screen and (max-width: 750px) {
      .p-form__input--long {
        width: 100%; } }
  .p-form__input--tel {
    width: 345px; }
    @media screen and (max-width: 750px) {
      .p-form__input--tel {
        width: 100%; } }
  .p-form__input--mail {
    width: 434px; }
    @media screen and (max-width: 750px) {
      .p-form__input--mail {
        width: 100%; } }
  .p-form__input--text {
    width: 668px;
    height: 167px;
    vertical-align: middle;
    resize: none; }
    @media screen and (max-width: 750px) {
      .p-form__input--text {
        width: 100%;
        height: 42.4vw; } }

.p-form__notearea {
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 36px 29px;
  width: 920px; }
  @media screen and (max-width: 750px) {
    .p-form__notearea {
      padding: 9.06667vw 4.26667vw;
      width: 100%; } }
  .p-form__notearea__text {
    margin-bottom: 30px; }
    @media screen and (max-width: 750px) {
      .p-form__notearea__text {
        margin-bottom: 4.26667vw; } }

.p-form--check {
  text-align: center;
  position: relative; }
  .p-form--check__box {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
    border: 1px solid #999;
    border-radius: 3px; }
    @media screen and (max-width: 750px) {
      .p-form--check__box {
        width: 5.33333vw;
        height: 5.33333vw;
        margin-right: 2.66667vw; } }
  .p-form--check__text {
    cursor: pointer; }
    @media print, screen and (min-width: 1440px) {
      .p-form--check__text {
        transition: ease .5s; } }
    .p-form--check__text:hover {
      opacity: 0.8; }

.p-form__submit {
  margin: 50px 0 0;
  text-align: center; }
  @media screen and (max-width: 750px) {
    .p-form__submit {
      margin-top: 10.66667vw; } }

.error::before {
  content: "";
  background-image: url(../image/common/icon_check.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-bottom: 2px;
  margin-right: 10px; }

.mw_wp_form .error, .p-form--check .error {
  display: block;
  z-index: 1;
  color: #a20000;
  font-size: 16px;
  margin-top: 3px; }

.p-news__list {
  padding: 0 20px; }
  @media screen and (max-width: 750px) {
    .p-news__list {
      padding: 0;
      margin-top: 13.33333vw; } }
  .p-news__list__line {
    display: flex;
    align-items: baseline;
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0; }
    @media screen and (max-width: 750px) {
      .p-news__list__line {
        flex-wrap: wrap;
        font-size: 14px;
        font-size: 3.73333vw;
        padding: 6.66667vw 0 5.33333vw; } }
    .p-news__list__line:last-child {
      border-bottom: none; }
      @media screen and (max-width: 750px) {
        .p-news__list__line:last-child {
          border-bottom: 1px solid #e0e0e0; } }
    .p-news__list__line__date {
      width: 170px;
      text-align: left;
      font-size: 1.4rem;
      font-weight: 700;
      vertical-align: top; }
      @media screen and (max-width: 750px) {
        .p-news__list__line__date {
          width: 100%;
          margin-bottom: 1.33333vw;
          font-size: 14px;
          font-size: 3.73333vw; } }
      .p-news__list__line__date__category {
        font-size: 1.2rem;
        line-height: 1;
        color: #fff;
        background-color: #004EA2;
        padding: 4px 15px;
        display: inline-block;
        margin-left: 20px; }
        @media screen and (max-width: 750px) {
          .p-news__list__line__date__category {
            width: 100%;
            font-size: 12px;
            font-size: 3.2vw;
            display: inline;
            margin-left: 5.33333vw; } }
    .p-news__list__line__title {
      width: 690px;
      margin-left: 20px; }
      .p-news__list__line__title a {
        transition: 500ms ease; }
        @media screen and (max-width: 750px) {
          .p-news__list__line__title a {
            transition: none; } }
      @media screen and (max-width: 750px) {
        .p-news__list__line__title {
          width: 100%;
          margin-left: 0;
          font-size: 16px;
          font-size: 4.26667vw; } }
      .p-news__list__line__title__new {
        margin-left: 10px;
        font-weight: 700;
        font-size: 1.4rem;
        color: red; }
        @media screen and (max-width: 750px) {
          .p-news__list__line__title__new {
            font-size: 14px;
            font-size: 3.73333vw; } }

.p-news__line__date__text {
  margin-right: 30px; }
  @media screen and (max-width: 750px) {
    .p-news__line__date__text {
      margin-right: 5.33333vw; } }

@media screen and (max-width: 750px) {
  .p-news .l-container__main--2column {
    margin-bottom: 13.33333vw; } }

.p-news .l-container__main--2column .p-news__list {
  padding: 0;
  width: 682px; }
  @media screen and (max-width: 750px) {
    .p-news .l-container__main--2column .p-news__list {
      padding: 0;
      width: auto;
      margin: 0 0 13.33333vw; } }

.p-news .l-container__main--2column .p-news__line {
  display: flex;
  align-items: baseline;
  padding: 12px 0;
  border-bottom: 1px solid #e0e0e0; }
  @media screen and (max-width: 750px) {
    .p-news .l-container__main--2column .p-news__line {
      flex-wrap: wrap;
      font-size: 14px;
      font-size: 3.73333vw;
      padding: 5.33333vw 0 5.33333vw; } }
  .p-news .l-container__main--2column .p-news__line:last-child {
    border-bottom: none; }
    @media screen and (max-width: 750px) {
      .p-news .l-container__main--2column .p-news__line:last-child {
        border-bottom: 1px solid #e0e0e0; } }
  .p-news .l-container__main--2column .p-news__line__date {
    width: 160px;
    text-align: left;
    font-size: 1.4rem;
    font-weight: 700; }
    @media screen and (max-width: 750px) {
      .p-news .l-container__main--2column .p-news__line__date {
        width: 100%;
        margin-bottom: 1.33333vw;
        font-size: 14px;
        font-size: 3.73333vw; } }
    .p-news .l-container__main--2column .p-news__line__date__text {
      margin-right: 20px; }
      @media screen and (max-width: 750px) {
        .p-news .l-container__main--2column .p-news__line__date__text {
          margin-right: 5.33333vw; } }
    .p-news .l-container__main--2column .p-news__line__date__category {
      font-size: 1.2rem;
      line-height: 1;
      color: #fff;
      background-color: #004EA2;
      padding: 4px 15px;
      margin-left: 20px;
      display: inline-block; }
      @media screen and (max-width: 750px) {
        .p-news .l-container__main--2column .p-news__line__date__category {
          width: 100%;
          margin-left: 5.33333vw;
          font-size: 12px;
          font-size: 3.2vw;
          display: inline; } }
  .p-news .l-container__main--2column .p-news__line__title {
    width: 522px;
    margin-left: 20px; }
    @media screen and (max-width: 750px) {
      .p-news .l-container__main--2column .p-news__line__title {
        width: 100%;
        margin-left: 0; } }
    .p-news .l-container__main--2column .p-news__line__title a {
      font-size: 1.6rem; }
      @media screen and (max-width: 750px) {
        .p-news .l-container__main--2column .p-news__line__title a {
          font-size: 16px;
          font-size: 4.26667vw; } }
    .p-news .l-container__main--2column .p-news__line__title__new {
      margin-left: 10px;
      font-weight: 700;
      font-size: 1.4rem;
      color: red; }
      @media screen and (max-width: 750px) {
        .p-news .l-container__main--2column .p-news__line__title__new {
          font-size: 14px;
          font-size: 3.73333vw; } }
    .p-news .l-container__main--2column .p-news__line__title__pdf {
      vertical-align: middle;
      margin-left: 10px;
      line-height: 1; }

.menu-item {
  padding-left: 20px;
  position: relative; }
  @media screen and (max-width: 750px) {
    .menu-item {
      padding-left: 5.33333vw; } }
  .menu-item::before {
    content: "";
    background-image: url(../image/common/icon_arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: baseline;
    position: absolute;
    top: 10px;
    left: 0; }
    @media screen and (max-width: 750px) {
      .menu-item::before {
        width: 3.73333vw;
        height: 3.73333vw;
        top: 2.66667vw; } }
  .menu-item .sub-menu {
    margin-left: 1em; }
    @media screen and (max-width: 750px) {
      .menu-item .sub-menu {
        margin-left: 0; } }

.menu a {
  text-decoration: none; }
  .menu a:hover {
    text-decoration: underline; }

@media screen and (max-width: 750px) {
  .menu {
    margin-left: 1em; } }

.menu .menu-item {
  margin-top: 1em; }

.p-hero {
  position: relative;
  height: 180px;
  overflow: hidden; }
  @media screen and (max-width: 750px) {
    .p-hero {
      height: 34.66667vw; } }
  .p-hero__image__content {
    object-fit: cover;
    height: 180px;
    width: 100%;
    font-family: 'object-fit: cover; object-position:center center;'; }
  .p-hero__inner {
    width: 1132px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
    @media screen and (max-width: 750px) {
      .p-hero__inner {
        width: auto; } }
  .p-hero__title {
    color: #fff;
    padding: 58px 0;
    font-size: 3.2rem;
    font-weight: 700; }
    @media screen and (max-width: 750px) {
      .p-hero__title {
        padding: 10.93333vw 4vw;
        font-size: 24px;
        font-size: 6.4vw; } }

.p-inter__slider {
  width: 960px; }
  .p-inter__slider__content {
    width: 293px;
    margin: 0 20px;
    transition: .5s ease; }
    @media screen and (max-width: 750px) {
      .p-inter__slider__content {
        width: 78.13333vw;
        margin: 0;
        padding: 0 2.66667vw; } }
    .p-inter__slider__content:hover {
      opacity: 0.6; }
  .p-inter__slider__image {
    width: 293px; }
    @media screen and (max-width: 750px) {
      .p-inter__slider__image {
        width: 72.8vw; } }
    .p-inter__slider__image img {
      margin: 0 auto;
      height: 220px;
      object-fit: contain;
      font-family: 'object-fit: contain;'; }
      @media screen and (max-width: 750px) {
        .p-inter__slider__image img {
          height: 58.66667vw; } }
  .p-inter__slider__text {
    margin-top: 20px;
    line-height: 1.5; }
    .p-inter__slider__text:before {
      top: 5px; }
    @media screen and (max-width: 750px) {
      .p-inter__slider__text {
        margin-top: 6.4vw; }
        .p-inter__slider__text:before {
          top: 1.33333vw; } }

.p-inter .p-about__table--lined {
  width: 921px;
  margin: 0 auto; }
  @media screen and (max-width: 750px) {
    .p-inter .p-about__table--lined {
      width: 100%; } }
  .p-inter .p-about__table--lined__line {
    line-height: 1.5;
    padding-right: 20px; }
    @media screen and (max-width: 750px) {
      .p-inter .p-about__table--lined__line {
        padding: 5.33333vw 5.33333vw 0 0; }
        .p-inter .p-about__table--lined__line:first-child {
          padding-top: 0; } }
  .p-inter .p-about__table--lined__head {
    font-size: 14px;
    padding: 12px 0;
    width: 96px; }
    @media screen and (max-width: 750px) {
      .p-inter .p-about__table--lined__head {
        font-size: 14px;
        font-size: 3.73333vw;
        padding: 0; } }
  .p-inter .p-about__table--lined__text {
    padding: 12px 0; }
    @media screen and (max-width: 750px) {
      .p-inter .p-about__table--lined__text {
        padding: 3.2vw 0 5.33333vw; } }

.p-inter .slick-dots {
  bottom: -50px;
  border-bottom: none; }
  @media screen and (max-width: 750px) {
    .p-inter .slick-dots {
      bottom: -13.33333vw; } }

@media screen and (max-width: 750px) {
  .p-inter .slick-dotted.slick-slider {
    margin: 0 -4.26667vw 8vw;
    width: 100vw; } }

.p-inter .slick-list.draggable {
  margin-left: -20px; }
  @media screen and (max-width: 750px) {
    .p-inter .slick-list.draggable {
      margin-left: 0; } }

.p-side {
  width: 207px;
  font-size: 1.6rem; }
  @media screen and (max-width: 750px) {
    .p-side {
      width: 100%;
      font-size: 16px;
      font-size: 4.26667vw; } }
  .p-side__title {
    position: relative;
    border: 1px solid #e0e0e0;
    color: #004EA2;
    border-collapse: collapse;
    padding: 10px 20px;
    text-decoration: none; }
    @media screen and (max-width: 750px) {
      .p-side__title {
        padding: 2.4vw 5.33333vw; } }
    .p-side__title a {
      color: #004EA2;
      border-collapse: collapse;
      display: block;
      text-decoration: none; }
  .p-side__list {
    line-height: 1.5; }
    .p-side__list--video {
      line-height: 1.5; }
    .p-side__list__content {
      border: 1px solid #e0e0e0;
      border-top: none; }
      .p-side__list__content--link {
        text-decoration: none;
        transition: 500ms ease;
        display: block;
        padding: 12px 20px 12px 39px;
        font-size: 16px;
        transition: .5s ease; }
        @media screen and (max-width: 750px) {
          .p-side__list__content--link {
            padding: 3.2vw 5.33333vw 3.2vw 10.4vw;
            font-size: 16px;
            font-size: 4.26667vw;
            transition: none; } }
        .p-side__list__content--link:hover {
          color: #004EA2; }
      .p-side__list__content--video {
        padding: 10px 20px 10px 39px; }
        @media screen and (max-width: 750px) {
          .p-side__list__content--video {
            padding: 2.66667vw 5.33333vw 2.66667vw 10.4vw; } }
        .p-side__list__content--video a {
          text-decoration: none; }
          .p-side__list__content--video a::before {
            content: "";
            background-image: url(../image/common/icon_arrow.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 5px;
            vertical-align: baseline; }
            @media screen and (max-width: 750px) {
              .p-side__list__content--video a::before {
                width: 3.73333vw;
                height: 3.73333vw;
                margin-right: 1.33333vw; } }
      .p-side__list__content--image {
        border: 1px solid #e0e0e0;
        border-top: none;
        padding: 10px 20px; }
        @media screen and (max-width: 750px) {
          .p-side__list__content--image {
            padding: 2.66667vw 5.33333vw; } }
        .p-side__list__content--image a {
          transition: 500ms ease;
          text-decoration: none; }
          @media screen and (max-width: 750px) {
            .p-side__list__content--image a {
              transition: none; } }
          .p-side__list__content--image a:hover {
            opacity: 0.8; }
          .p-side__list__content--image a img {
            vertical-align: bottom;
            width: 100%; }
      .p-side__list__content__thumb {
        position: relative;
        padding: 5px 20px 18px;
        transition: 500ms ease;
        text-decoration: none; }
        .p-side__list__content__thumb:hover {
          opacity: 0.8; }
        @media screen and (max-width: 750px) {
          .p-side__list__content__thumb {
            padding: 1.33333vw 5.33333vw 4.8vw;
            transition: none; } }
        .p-side__list__content__thumb img {
          width: 100%; }
        .p-side__list__content__thumb::after {
          content: "";
          background-image: url(../image/common/icon_arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          width: 36px;
          height: 36px;
          display: block;
          position: absolute;
          top: 0;
          bottom: 8px;
          right: 0;
          left: 0;
          margin: auto;
          cursor: pointer;
          transition: 500ms  ease; }
          @media screen and (max-width: 750px) {
            .p-side__list__content__thumb::after {
              width: 9.6vw;
              height: 9.6vw;
              bottom: 2.13333vw;
              transition: none; } }
      .p-side__list__content__thumb-library {
        position: relative;
        padding: 5px 20px 18px;
        transition: 500ms ease;
        text-decoration: none; }
        .p-side__list__content__thumb-library:hover {
          opacity: 0.8; }
        @media screen and (max-width: 750px) {
          .p-side__list__content__thumb-library {
            padding: 1.33333vw 5.33333vw 4.8vw;
            transition: none; } }
        .p-side__list__content__thumb-library img {
          width: 100%; }
      .p-side__list__content__sub-title {
        padding: 0px 20px;
        border: 1px solid #e0e0e0;
        border-top: none;
        border-bottom: none;
        background-color: #f9f9f9;
        color: #004EA2;
        font-weight: 500;
        font-size: 12px;
        line-height: 2;
        padding: 0px 20px; }
  .p-side__inner {
    margin-bottom: 30px; }
    .p-side__inner:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 750px) {
      .p-side__inner {
        margin-bottom: 6.66667vw; } }
    .p-side__inner .c-list--tag__link {
      display: block;
      padding: 15px 45px; }
      @media screen and (max-width: 750px) {
        .p-side__inner .c-list--tag__link {
          padding: 2.66667vw 12vw; } }
      .p-side__inner .c-list--tag__link::before {
        left: 20px; }
        @media screen and (max-width: 750px) {
          .p-side__inner .c-list--tag__link::before {
            left: 5.33333vw; } }
  .p-side--project {
    width: 265px;
    font-size: 1.6rem; }
    @media screen and (max-width: 750px) {
      .p-side--project {
        width: 100%;
        margin-top: 12.8vw;
        font-size: 16px;
        font-size: 4.26667vw; } }

.js-opener {
  cursor: pointer; }

.slick-dots li {
  margin: 0 -1px; }

.p-top__fv {
  margin: 0 auto 31px;
  position: relative;
  line-height: 1; }
  @media screen and (max-width: 750px) {
    .p-top__fv {
      width: 100%; } }
  .p-top__fv__image__content {
    width: auto;
    height: 38.88vw;
    background-repeat: no-repeat;
    background-size: contain; }
    @media screen and (max-width: 1260px) {
      .p-top__fv__image__content {
        height: 490px; } }
    @media screen and (max-width: 750px) {
      .p-top__fv__image__content {
        height: 113.33vw !important; } }
    .p-top__fv__image__content img {
      width: 100%; }
  .p-top__fv__catch {
    text-align: left;
    position: absolute;
    color: #fff;
    text-shadow: 0px 0px 12px rgba(0, 0, 0, 0.8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 1.2;
    width: 1020px;
    height: 300px;
    font-weight: 700; }
    @media screen and (max-width: 750px) {
      .p-top__fv__catch {
        width: auto;
        height: auto;
        top: auto;
        right: auto;
        left: 4vw;
        bottom: 21.33333vw; } }
    .p-top__fv__catch__large {
      font-size: 6.8rem;
      margin-bottom: 32px; }
      @media screen and (max-width: 750px) {
        .p-top__fv__catch__large {
          font-size: 48px;
          font-size: 12.8vw;
          margin-bottom: 0;
          line-height: 1.2; } }
    .p-top__fv__catch__small {
      font-size: 2rem;
      line-height: 3.6rem; }
      @media screen and (max-width: 750px) {
        .p-top__fv__catch__small {
          display: none; } }
  .p-top__fv__dots {
    margin: 25px 0; }
    .p-top__fv__dots__content {
      width: 8px;
      height: 8px;
      border: none;
      border-radius: 100%;
      background-color: #e0e0e0;
      margin: 0 4px;
      padding: 0; }

.p-top__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 8px;
  border-bottom: #e0e0e0 solid 1px;
  margin-bottom: 20px; }
  @media screen and (max-width: 750px) {
    .p-top__head {
      padding: 0;
      margin-bottom: -10.66667vw; } }

.p-top__news {
  margin-bottom: 60px; }
  @media screen and (max-width: 750px) {
    .p-top__news {
      margin-bottom: 16vw; } }

.slick-dots {
  bottom: -24px;
  margin-bottom: -11px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px; }
  @media screen and (max-width: 750px) {
    .slick-dots {
      bottom: -9.06667vw;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 5.06667vw; } }
  .slick-dots li button:before {
    color: #e0e0e0;
    font-size: 10px;
    opacity: 1;
    transition: .5s ease; }
  .slick-dots li button:hover::before {
    color: #004EA2; }
  @media screen and (max-width: 750px) {
    .slick-dots li button {
      font-size: 10px;
      font-size: 2.66667vw;
      width: 5.33333vw;
      height: 5.33333vw; } }
  .slick-dots li.slick-active button:before {
    color: #004EA2;
    font-size: 10px;
    opacity: 1; }
    @media screen and (max-width: 750px) {
      .slick-dots li.slick-active button:before {
        font-size: 10px;
        font-size: 2.66667vw;
        width: 5.33333vw;
        height: 5.33333vw; } }

@media screen and (max-width: 750px) {
  .slick-dotted.slick-slider {
    margin-bottom: 16vw; } }

.p-editor {
  width: 960px;
  margin: 0 auto; }
  .p-editor ul {
    display: flex; }
  @media screen and (max-width: 750px) {
    .p-editor {
      width: 100%;
      margin: 0;
      padding: 0 4.26667vw; } }
  .p-editor__titlearea {
    background-color: rgba(189, 189, 189, 0.2);
    padding: 20px;
    margin-bottom: 40px;
    font-weight: 700;
    border-radius: 4px; }
    @media screen and (max-width: 750px) {
      .p-editor__titlearea {
        margin-bottom: 6.66667vw;
        padding: 5.33333vw 4vw; } }
    .p-editor__titlearea__head {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      align-items: flex-start; }
      @media screen and (max-width: 750px) {
        .p-editor__titlearea__head {
          font-size: 14px;
          font-size: 3.73333vw; } }
      .p-editor__titlearea__head__time {
        margin-right: 20px; }
        @media screen and (max-width: 750px) {
          .p-editor__titlearea__head__time {
            margin-right: 5.33333vw; } }
      .p-editor__titlearea__head__category {
        display: flex;
        flex-wrap: wrap; }
    .p-editor__titlearea__title {
      font-size: 2.2rem;
      color: #004EA2;
      line-height: 1.8; }
      @media screen and (max-width: 750px) {
        .p-editor__titlearea__title {
          font-size: 20px;
          font-size: 5.33333vw; } }
  .p-editor__inner {
    padding: 0 20px;
    font-size: 1.6rem;
    line-height: 1.8; }
    @media screen and (max-width: 750px) {
      .p-editor__inner {
        padding: 0;
        font-size: 16px;
        font-size: 4.26667vw; } }
    .p-editor__inner--news {
      padding: 0 240px 0 20px; }
      @media screen and (max-width: 750px) {
        .p-editor__inner--news {
          padding: 0; } }
    .p-editor__inner a {
      color: #004EA2; }
    .p-editor__inner h1 {
      font-weight: 400;
      font-size: 2rem;
      color: #333;
      border-left: 5px solid #004EA2;
      border-radius: 4px;
      padding: 9px 15px;
      background-color: #f9f9f9;
      margin-bottom: 30px;
      text-decoration: none;
      line-height: 1.6; }
      @media screen and (max-width: 750px) {
        .p-editor__inner h1 {
          font-size: 20px;
          font-size: 5.33333vw;
          margin-bottom: 6.4vw;
          padding: 2.4vw 5.33333vw; } }
    .p-editor__inner h2 {
      padding: 3px 0 3px 10px;
      margin: 50px 0 25px;
      border-left: 4px solid #004EA2;
      border-bottom: 1px solid #004EA2; }
      @media screen and (max-width: 750px) {
        .p-editor__inner h2 {
          font-size: 16px;
          font-size: 4.26667vw; } }
    .p-editor__inner h3 {
      font-size: 1.6rem;
      font-weight: 700;
      color: #004EA2; }
      @media screen and (max-width: 750px) {
        .p-editor__inner h3 {
          font-size: 16px;
          font-size: 4.26667vw;
          margin-bottom: 4.26667vw; } }
    .p-editor__inner p {
      margin-bottom: 30px; }
      @media screen and (max-width: 750px) {
        .p-editor__inner p {
          margin-bottom: 8vw; } }
      .p-editor__inner p:last-child {
        margin-bottom: 0; }
    .p-editor__inner strong {
      font-weight: 700; }
    .p-editor__inner img {
      max-width: 100%; }
    .p-editor__inner .wp-caption {
      max-width: 100% !important; }
    .p-editor__inner .alignleft {
      display: block;
      margin: 0 auto 15px 15px;
      float: left; }
    .p-editor__inner .aligncenter {
      display: block;
      margin: 0 auto; }
    .p-editor__inner .alignright {
      display: block;
      margin: 0 15px auto 15px;
      float: right; }
    .p-editor__inner .alignnone {
      display: block;
      margin: 0; }
    .p-editor__inner figure {
      margin-bottom: 32px; }
      @media screen and (max-width: 750px) {
        .p-editor__inner figure {
          margin-bottom: 8.53333vw; } }
    .p-editor__inner figcaption {
      color: #999; }
    .p-editor__inner iframe {
      width: 100%; }
    .p-editor__inner ol {
      counter-reset: item;
      list-style-type: none;
      padding-left: 0;
      margin: 20px 0; }
      @media screen and (max-width: 750px) {
        .p-editor__inner ol {
          margin-top: -2.66667vw; } }
      .p-editor__inner ol li {
        padding-left: 1.6em;
        position: relative; }
        @media screen and (max-width: 750px) {
          .p-editor__inner ol li {
            font-size: 16px;
            font-size: 4.26667vw; } }
        .p-editor__inner ol li:before {
          color: #004EA2;
          content: counter(item);
          counter-increment: item;
          position: absolute;
          margin: auto;
          top: 0;
          left: 0;
          text-align: center;
          width: 1.2em; }
          @media screen and (max-width: 750px) {
            .p-editor__inner ol li:before {
              width: 4.8vw; } }
    .p-editor__inner ul {
      display: block;
      list-style-type: none;
      padding-left: 0;
      margin: 20px 0; }
      @media screen and (max-width: 750px) {
        .p-editor__inner ul {
          margin-top: -2.66667vw; } }
      .p-editor__inner ul li {
        padding-left: 1.2em;
        position: relative; }
        @media screen and (max-width: 750px) {
          .p-editor__inner ul li {
            font-size: 16px;
            font-size: 4.26667vw; } }
        .p-editor__inner ul li:before {
          content: "・";
          color: #004EA2;
          position: absolute;
          width: 1em;
          top: 0;
          left: 0; }
    .p-editor__inner table {
      max-width: 100%; }
      @media screen and (max-width: 750px) {
        .p-editor__inner table {
          display: block;
          overflow: scroll;
          margin-left: calc(((91.73333vw - 100%) / 2) * -1);
          margin-right: calc(((100vw - 100%) / 2) * -1); } }
    .p-editor__inner tr, .p-editor__inner th, .p-editor__inner td {
      border: 1px solid #e0e0e0;
      padding: 5px 1em; }
      @media screen and (max-width: 750px) {
        .p-editor__inner tr, .p-editor__inner th, .p-editor__inner td {
          padding: 1.33333vw 1em; } }
    @media screen and (max-width: 750px) {
      .p-editor__inner th, .p-editor__inner td {
        width: max-content !important; } }
    @media screen and (max-width: 750px) {
      .p-editor__inner iframe {
        width: 100%; } }
  .p-editor__movie {
    width: 70%;
    position: relative;
    padding-top: 39.375%; }
    @media screen and (max-width: 750px) {
      .p-editor__movie {
        width: 100%;
        padding-top: 56.25%; } }
    .p-editor__movie iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%; }

.l-container__main--2column .p-editor {
  padding-right: 20px;
  width: 655px; }
  @media screen and (max-width: 750px) {
    .l-container__main--2column .p-editor {
      width: 100%;
      padding: 0; } }
  .l-container__main--2column .p-editor__inner {
    padding: 0px; }
    @media screen and (max-width: 750px) {
      .l-container__main--2column .p-editor__inner {
        padding: 0; } }

.p-inter .l-container__main--2column .p-editor {
  padding-right: 25px;
  width: 713px; }
  @media screen and (max-width: 750px) {
    .p-inter .l-container__main--2column .p-editor {
      width: 100%;
      padding: 0; } }
  .p-inter .l-container__main--2column .p-editor__inner {
    padding: 0px; }
    @media screen and (max-width: 750px) {
      .p-inter .l-container__main--2column .p-editor__inner {
        padding: 0; } }

@media screen and (max-width: 750px) {
  .gallery {
    overflow: scroll;
    display: flex;
    flex-wrap: nowrap;
    width: 100vw;
    margin-left: calc(((100vw - 100%) / 2) * -1) !important;
    margin-right: calc(((100vw - 100%) / 2) * -1) !important; } }

.gallery img {
  border: none !important; }

.gallery .gallery-item {
  width: 31.39% !important;
  margin-right: 20px; }
  @media screen and (max-width: 750px) {
    .gallery .gallery-item:first-child {
      margin-left: 4.26667vw; } }
  .gallery .gallery-item:nth-child(4n - 1) {
    margin-right: 0; }
    @media screen and (max-width: 750px) {
      .gallery .gallery-item:nth-child(4n - 1) {
        margin-right: 5.33333vw; } }
  @media screen and (max-width: 750px) {
    .gallery .gallery-item {
      margin-right: 5.33333vw;
      float: none;
      min-width: 57.6vw !important; }
      .gallery .gallery-item:last-child {
        margin-right: 0; } }

#TermsOfUse, #InformationSecurityPolicy {
  padding-top: 100px;
  margin-top: -100px; }
  @media screen and (max-width: 750px) {
    #TermsOfUse, #InformationSecurityPolicy {
      padding-top: 26.66667vw;
      margin-top: -26.66667vw; } }

.p-pager {
  width: 960px;
  margin: 70px 0 80px;
  padding: 0 35px;
  border-top: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 750px) {
    .p-pager {
      width: 100%;
      padding: 0;
      font-size: 16px;
      font-size: 4.26667vw; } }
  .p-pager__return {
    padding: 5px 0; }
    .p-pager__return:hover {
      text-decoration: underline; }
    @media screen and (max-width: 750px) {
      .p-pager__return {
        padding: 1.33333vw;
        transition: none; } }
    .p-pager__return__image {
      display: inline-block;
      margin-right: 10px; }
      @media screen and (max-width: 750px) {
        .p-pager__return__image {
          margin-right: 2.66667vw; } }
    .p-pager__return a {
      color: #333;
      text-decoration: none; }
      @media screen and (max-width: 750px) {
        .p-pager__return a {
          transition: none; } }
      .p-pager__return a:hover {
        text-decoration: underline; }
  .p-pager__next {
    padding: 5px 0; }
    .p-pager__next:hover {
      text-decoration: underline; }
    @media screen and (max-width: 750px) {
      .p-pager__next {
        padding: 1.33333vw;
        transition: none; } }
    .p-pager__next__image {
      display: inline-block;
      margin-left: 10px; }
      @media screen and (max-width: 750px) {
        .p-pager__next__image {
          margin-left: 2.66667vw; } }
    .p-pager__next a {
      color: #333;
      text-decoration: none; }
  .p-pager--2colum {
    padding: 20px 0 0 0;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 750px) {
      .p-pager--2colum {
        padding-top: 5.33333vw;
        margin-bottom: 12.8vw; } }
    .p-pager--2colum .p-pager__return {
      padding: 0; }
    .p-pager--2colum .p-pager__next {
      padding: 0; }
  .p-pager--archives {
    margin: 0 auto 50px;
    vertical-align: middle; }
    @media screen and (max-width: 750px) {
      .p-pager--archives {
        margin: -2.66667vw 0 10.66667vw;
        width: 100%; } }
    .p-pager--archives__list {
      display: flex;
      padding: 0 40px;
      margin: 0 auto;
      justify-content: center;
      font-weight: 700; }
      @media screen and (max-width: 750px) {
        .p-pager--archives__list {
          padding: 0;
          justify-content: center; } }
    .p-pager--archives__content {
      margin: 0 5px;
      border: 1px solid #004EA2;
      border-radius: 4px;
      display: flex;
      width: 30px;
      height: 30px;
      justify-content: center;
      align-items: center; }
      .p-pager--archives__content:hover {
        color: #fff;
        background-color: #004EA2; }
      .p-pager--archives__content:last-child {
        margin-right: 0; }
      .p-pager--archives__content:first-child {
        margin-left: 0; }
      @media screen and (max-width: 750px) {
        .p-pager--archives__content {
          width: 8vw;
          height: 8vw;
          margin: 0 2.13333vw 0 0; }
          .p-pager--archives__content:last-child {
            margin-right: 0; } }
      .p-pager--archives__content--side {
        width: 70px; }
        @media screen and (max-width: 750px) {
          .p-pager--archives__content--side {
            width: 8vw; } }
    .p-pager--archives__link {
      text-decoration: none;
      color: #004EA2;
      transition: .5s ease;
      display: flex;
      width: 100%;
      justify-content: center;
      align-content: center; }
      .p-pager--archives__link:hover {
        color: #fff; }
      @media screen and (max-width: 750px) {
        .p-pager--archives__link {
          transition: none; } }
      .p-pager--archives__link--prev {
        position: relative;
        margin-left: 17px; }
        .p-pager--archives__link--prev:hover::before {
          background-image: url(../image/common/icon_prev-wh.svg); }
        .p-pager--archives__link--prev:before {
          content: "";
          background-image: url(../image/common/icon_prev.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          display: inline-block;
          width: 8px;
          height: 6px;
          margin: auto;
          top: 0;
          bottom: 0;
          position: absolute;
          left: -7px;
          transition: .5s ease; }
          @media screen and (max-width: 750px) {
            .p-pager--archives__link--prev:before {
              transition: none;
              right: 0;
              left: 0;
              width: 2.93333vw;
              height: 2.13333vw; } }
        @media screen and (max-width: 750px) {
          .p-pager--archives__link--prev {
            margin-left: 0;
            padding: 4vw; } }
      .p-pager--archives__link--next {
        position: relative;
        margin-right: 17px; }
        .p-pager--archives__link--next:hover::after {
          background-image: url(../image/common/icon_next-wh.svg); }
        .p-pager--archives__link--next::after {
          content: "";
          background-image: url(../image/common/icon_next.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          display: inline-block;
          width: 8px;
          height: 6px;
          margin: auto;
          top: 0;
          bottom: 0;
          position: absolute;
          right: -8px;
          transition: .5s ease; }
          @media screen and (max-width: 750px) {
            .p-pager--archives__link--next::after {
              transition: none;
              right: 0;
              left: 0;
              width: 3.2vw;
              height: 2.4vw; } }
        @media screen and (max-width: 750px) {
          .p-pager--archives__link--next {
            margin-right: 0;
            padding: 4vw; } }
      .p-pager--archives__link--first {
        margin-left: 17px;
        position: relative; }
        .p-pager--archives__link--first:hover::before {
          background-image: url(../image/common/icon_first-wh.svg); }
        .p-pager--archives__link--first:before {
          content: "";
          background-image: url(../image/common/icon_first.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          display: inline-block;
          width: 8px;
          height: 6px;
          margin: auto;
          top: 0;
          bottom: 0;
          position: absolute;
          left: -7px;
          transition: .5s ease; }
          @media screen and (max-width: 750px) {
            .p-pager--archives__link--first:before {
              transition: none;
              right: 0;
              left: 0;
              width: 3.73333vw;
              height: 2.13333vw; } }
        @media screen and (max-width: 750px) {
          .p-pager--archives__link--first {
            margin-left: 0;
            padding: 4vw 2.13333vw; } }
      .p-pager--archives__link--last {
        position: relative;
        margin-right: 17px; }
        .p-pager--archives__link--last::after {
          content: "";
          background-image: url(../image/common/icon_last.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;
          display: inline-block;
          width: 8px;
          height: 6px;
          margin: auto;
          top: 0;
          position: absolute;
          right: -8px;
          bottom: 0;
          transition: .5s ease; }
          @media screen and (max-width: 750px) {
            .p-pager--archives__link--last::after {
              transition: none;
              right: 0;
              left: 0;
              width: 3.73333vw;
              height: 2.13333vw; } }
        .p-pager--archives__link--last:hover::after {
          background-image: url(../image/common/icon_last-wh.svg); }
        @media screen and (max-width: 750px) {
          .p-pager--archives__link--last {
            margin-right: 0;
            padding: 4vw 2.13333vw; } }
    .p-pager--archives .current {
      background-color: #004EA2; }
      .p-pager--archives .current .p-pager--archives__link {
        color: #fff; }
    .p-pager--archives__image {
      vertical-align: middle;
      margin-bottom: 2px; }
      @media screen and (max-width: 750px) {
        .p-pager--archives__image {
          margin-bottom: 1.33333vw; } }

.p-project__list__content {
  margin-bottom: 15px; }
  .p-project__list__content:last-child {
    margin-bottom: 0; }

.p-project__content {
  width: 960px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 100px auto 0;
  line-height: 1.8; }
  @media screen and (max-width: 750px) {
    .p-project__content:first-of-type {
      margin-top: -0.8vw; } }
  @media screen and (max-width: 750px) {
    .p-project__content {
      display: block;
      margin: 8vw 0 12.8vw;
      padding: 0 4vw;
      width: 100%;
      line-height: 1.4; } }
  .p-project__content .c-title--regular {
    margin-bottom: 21px; }
    @media screen and (max-width: 750px) {
      .p-project__content .c-title--regular {
        margin-bottom: 4.26667vw; } }
  .p-project__content__image {
    text-align: center;
    width: 460px; }
    @media screen and (max-width: 750px) {
      .p-project__content__image {
        width: 91.46667vw; } }
  .p-project__content__textarea {
    width: 460px;
    margin: 20px 0 0 40px; }
    @media screen and (max-width: 750px) {
      .p-project__content__textarea {
        margin: 3.73333vw 0 0 0;
        width: 100%; } }
  .p-project__content:nth-child(odd) {
    flex-direction: row-reverse;
    justify-content: space-between; }
    .p-project__content:nth-child(odd) .p-project__content__textarea {
      width: 460px;
      margin: 20px 40px 0 0; }
      @media screen and (max-width: 750px) {
        .p-project__content:nth-child(odd) .p-project__content__textarea {
          margin: 3.73333vw 0 0 0;
          width: 100%; } }
  .p-project__content__text {
    margin: 0 0 5px 25px; }
    @media screen and (max-width: 750px) {
      .p-project__content__text {
        margin: 0 0 1.33333vw 0;
        font-size: 16px;
        font-size: 4.26667vw; } }
    .p-project__content__text:last-child {
      margin-bottom: 0; }
  .p-project__content__link {
    margin-left: 25px;
    font-weight: 700;
    line-height: 1.5; }
    @media screen and (max-width: 750px) {
      .p-project__content__link {
        margin: 0; } }
    .p-project__content__link a {
      color: #004EA2;
      font-weight: 700;
      text-decoration: underline;
      margin-bottom: 10px;
      font-size: 1.8rem; }
      .p-project__content__link a:hover {
        text-decoration: none; }
      @media screen and (max-width: 750px) {
        .p-project__content__link a {
          margin: 0;
          font-size: 18px;
          font-size: 4.8vw; } }

.p-support__text {
  margin-bottom: 50px; }
  @media screen and (max-width: 750px) {
    .p-support__text {
      margin-bottom: 12.8vw; } }

.p-support__btn-box {
  padding: 0 135px;
  text-align: center;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 750px) {
    .p-support__btn-box {
      display: block;
      padding: 0; } }

.p-library__list {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline; }
  .p-library__list__link {
    text-decoration: none; }
  .p-library__list__content {
    margin: 30px 30px 0 0;
    transition: .5s ease;
    width: 280px; }
    .p-library__list__content:hover {
      opacity: 0.8; }
    @media screen and (max-width: 750px) {
      .p-library__list__content {
        width: 91.46667vw;
        transition: none;
        margin: 0 0 8.53333vw 0; } }
    .p-library__list__content:nth-child(-n+3) {
      margin-top: 0; }
      @media screen and (max-width: 750px) {
        .p-library__list__content:nth-child(-n+3) {
          margin: 0 0 8.53333vw 0; } }
    .p-library__list__content:nth-child(3n) {
      margin-right: 0; }
      @media screen and (max-width: 750px) {
        .p-library__list__content:nth-child(3n) {
          margin: 0 0 8.53333vw 0; } }
    @media screen and (max-width: 750px) {
      .p-library__list__content:last-child {
        margin-bottom: 0; } }
  .p-library__list__image {
    display: flex;
    flex-direction: column;
    align-content: center; }
    .p-library__list__image img {
      object-fit: contain;
      height: 180px;
      font-family: 'object-fit:contain'; }
      @media screen and (max-width: 750px) {
        .p-library__list__image img {
          width: 91.46667vw;
          height: 66.66667vw; } }
  .p-library__list__text {
    margin-top: 10px;
    line-height: 1.5; }
    @media screen and (max-width: 750px) {
      .p-library__list__text {
        margin-top: 2.66667vw; } }

.p-modal {
  display: none;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: auto; }
  .p-modal__outer {
    background: rgba(51, 51, 51, 0.65);
    height: 100vh;
    position: fixed;
    width: 100%; }
  .p-modal__scrollarea {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: auto;
    min-width: 1260px;
    min-height: 703px; }
    @media screen and (max-width: 750px) {
      .p-modal__scrollarea {
        min-height: auto;
        min-width: auto; } }
  @media screen and (max-width: 750px) and (orientation: landscape) {
    .p-modal__scrollarea {
      height: 100vw; } }

@media screen and (min-width: 750px) and (max-height: 850px) {
  .p-modal__scrollarea {
    min-height: 890px; } }
  .p-modal__content {
    background: #fff;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 960px; }

@media screen and (min-width: 750px) and (max-height: 850px) {
  .p-modal__content {
    background: #fff;
    left: 50%;
    position: absolute;
    top: 60%;
    transform: translate(-50%, -60%);
    width: 960px; } }
    @media screen and (max-width: 750px) {
      .p-modal__content {
        width: 96vw; } }
  @media screen and (max-width: 750px) and (orientation: landscape) {
    .p-modal__content {
      width: 70%; } }
    .p-modal__content video {
      width: 100%; }
    .p-modal__content__inner {
      padding: 10px 30px 15px; }
      @media screen and (max-width: 750px) {
        .p-modal__content__inner {
          padding: 0 2.66667vw 6.4vw 4vw; } }
    .p-modal__content__text {
      font-size: 1.4rem; }
      @media screen and (max-width: 750px) {
        .p-modal__content__text {
          font-size: 14px;
          font-size: 3.73333vw; } }
  .p-modal__button {
    margin: 0 auto;
    color: #004EA2;
    border: 1px solid #004EA2;
    border-radius: 4px;
    text-align: center;
    padding: 10px 75px;
    transition: 500ms ease;
    font-weight: 700;
    font-size: 1.4rem;
    text-decoration: none;
    background-color: #fff;
    display: block;
    margin-top: 30px; }
    .p-modal__button:hover {
      color: #fff;
      background-color: #004EA2; }
    @media screen and (max-width: 750px) {
      .p-modal__button {
        padding: 2.66667vw 20vw;
        font-size: 14px;
        font-size: 3.73333vw;
        margin-top: 1.33333vw;
        transition: none; } }
  .p-modal__close-line {
    top: -35px;
    right: -35px;
    z-index: 3;
    position: absolute;
    font-weight: 700;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    line-height: 3.5;
    cursor: pointer;
    transition: .2s;
    height: 35px;
    width: 35px;
    font-size: 2.5rem; }
    .p-modal__close-line:hover {
      opacity: .7; }
    @media screen and (max-width: 750px) {
      .p-modal__close-line {
        top: -10.66667vw;
        right: 0;
        height: 9.33333vw;
        width: 9.33333vw;
        font-size: 25px;
        font-size: 6.66667vw;
        padding-bottom: 0.53333vw;
        padding-left: 0.26667vw; } }

body.fixed {
  position: fixed;
  width: 100%;
  left: 0; }

.p-ibaraki__news {
  display: grid;
  gap: 20px; }
  @media screen and (max-width: 750px) {
    .p-ibaraki__news {
      gap: 2.66667vw; } }
  .p-ibaraki__news__subtitle {
    position: relative;
    padding: 10px 20px;
    color: #004EA2;
    border: 1px solid #e0e0e0; }
    @media screen and (max-width: 750px) {
      .p-ibaraki__news__subtitle {
        padding: 2.4vw 5.33333vw;
        margin-bottom: 0 !important; } }
    .p-ibaraki__news__subtitle.js-opened:after {
      transform: rotate(180deg); }
    .p-ibaraki__news__subtitle:hover {
      opacity: .8;
      transition: .3s; }
  .p-ibaraki__news__content {
    padding: 20px; }
    @media screen and (max-width: 750px) {
      .p-ibaraki__news__content {
        padding: 2.66667vw; } }

.u-align--center {
  text-align: center; }
  @media print, screen and (min-width: 1440px) {
    .u-align--center-pc {
      text-align: center; } }
  @media screen and (max-width: 750px) {
    .u-align--center-sp {
      text-align: center; } }

.u-align--right {
  text-align: right; }
  @media print, screen and (min-width: 1440px) {
    .u-align--right-pc {
      text-align: right; } }
  @media screen and (max-width: 750px) {
    .u-align--right-sp {
      text-align: right; } }

.u-align--left {
  text-align: left; }
  @media print, screen and (min-width: 1440px) {
    .u-align--left-pc {
      text-align: left; } }
  @media screen and (max-width: 750px) {
    .u-align--left-sp {
      text-align: left; } }

@media print, screen and (min-width: 751px) {
  .u-display--block-pc {
    display: block; } }

@media screen and (max-width: 750px) {
  .u-display--block-sp {
    display: block; } }

@media print, screen and (min-width: 751px) {
  .u-display--inline-pc {
    display: inline-block; } }

@media screen and (max-width: 750px) {
  .u-display--inline-sp {
    display: inline-block; } }

@media screen and (max-width: 750px) {
  .u-show--pc {
    display: none !important; } }

@media print, screen and (min-width: 751px) {
  .u-show--pc {
    display: inherit !important; } }

@media screen and (max-width: 750px) {
  .u-show--sp {
    display: inherit !important; } }

@media print, screen and (min-width: 751px) {
  .u-show--sp {
    display: none !important; } }

@media screen and (max-width: 340px) {
  .u-show--xs {
    display: inherit !important; } }

@media print, screen and (min-width: 341px) {
  .u-show--xs {
    display: none !important; } }

@media print, screen and (min-width: 751px) {
  .u-hide--pc {
    display: none !important; } }

@media screen and (max-width: 750px) {
  .u-hide--sp {
    display: none !important; } }

/* margin, padding
----------------------------------------------------------------- */
.u-mg--0 {
  margin: 0 !important; }

.u-mg--t-0 {
  margin-top: 0 !important; }

.u-mg--t-xl {
  margin-top: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--t-xl {
      margin-top: 50px !important; } }

.u-mg--t-lg {
  margin-top: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--t-lg {
      margin-top: 40px !important; } }

.u-mg--t-md {
  margin-top: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--t-md {
      margin-top: 30px !important; } }

.u-mg--t-sm {
  margin-top: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--t-sm {
      margin-top: 20px !important; } }

.u-mg--t-xs {
  margin-top: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--t-xs {
      margin-top: 10px !important; } }

.u-mg--r-0 {
  margin-right: 0 !important; }

.u-mg--r-xl {
  margin-right: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--r-xl {
      margin-right: 50px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--r-xl {
      margin-right: 13.33333vw !important; } }

.u-mg--r-lg {
  margin-right: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--r-lg {
      margin-right: 40px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--r-lg {
      margin-right: 10.66667vw !important; } }

.u-mg--r-md {
  margin-right: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--r-md {
      margin-right: 30px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--r-md {
      margin-right: 8vw !important; } }

.u-mg--r-sm {
  margin-right: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--r-sm {
      margin-right: 20px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--r-sm {
      margin-right: 5.33333vw !important; } }

.u-mg--r-xs {
  margin-right: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--r-xs {
      margin-right: 10px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--r-xs {
      margin-right: 2.66667vw !important; } }

.u-mg--b-0 {
  margin-bottom: 0 !important; }

.u-mg--b-xl {
  margin-bottom: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--b-xl {
      margin-bottom: 50px !important; } }

.u-mg--b-lg {
  margin-bottom: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--b-lg {
      margin-bottom: 40px !important; } }

.u-mg--b-md {
  margin-bottom: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--b-md {
      margin-bottom: 30px !important; } }

.u-mg--b-sm {
  margin-bottom: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--b-sm {
      margin-bottom: 20px !important; } }

.u-mg--b-xs {
  margin-bottom: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--b-xs {
      margin-bottom: 10px !important; } }

.u-mg--l-0 {
  margin-left: 0 !important; }

.u-mg--l-xl {
  margin-left: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--l-xl {
      margin-left: 50px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--l-xl {
      margin-left: 13.33333vw !important; } }

.u-mg--l-lg {
  margin-left: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--l-lg {
      margin-left: 40px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--l-lg {
      margin-left: 10.66667vw !important; } }

.u-mg--l-md {
  margin-left: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--l-md {
      margin-left: 30px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--l-md {
      margin-left: 8vw !important; } }

.u-mg--l-sm {
  margin-left: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--l-sm {
      margin-left: 20px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--l-sm {
      margin-left: 5.33333vw !important; } }

.u-mg--l-xs {
  margin-left: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-mg--l-xs {
      margin-left: 10px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-mg--l-xs {
      margin-left: 2.66667vw !important; } }

.u-mg--t-auto {
  margin-top: auto !important; }

.u-mg--r-auto {
  margin-right: auto !important; }

.u-mg--b-auto {
  margin-bottom: auto !important; }

.u-mg--l-auto {
  margin-left: auto !important; }

.u-pd--0 {
  padding: 0 !important; }

.u-pd--t-0 {
  padding-top: 0 !important; }

.u-pd--t-xl {
  padding-top: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--t-xl {
      padding-top: 50px !important; } }

.u-pd--t-lg {
  padding-top: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--t-lg {
      padding-top: 40px !important; } }

.u-pd--t-md {
  padding-top: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--t-md {
      padding-top: 30px !important; } }

.u-pd--t-sm {
  padding-top: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--t-sm {
      padding-top: 20px !important; } }

.u-pd--t-xs {
  padding-top: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--t-xs {
      padding-top: 10px !important; } }

.u-pd--r-0 {
  padding-right: 0 !important; }

.u-pd--r-xl {
  padding-right: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--r-xl {
      padding-right: 50px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--r-xl {
      padding-right: 13.33333vw !important; } }

.u-pd--r-lg {
  padding-right: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--r-lg {
      padding-right: 40px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--r-lg {
      padding-right: 10.66667vw !important; } }

.u-pd--r-md {
  padding-right: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--r-md {
      padding-right: 30px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--r-md {
      padding-right: 8vw !important; } }

.u-pd--r-sm {
  padding-right: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--r-sm {
      padding-right: 20px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--r-sm {
      padding-right: 5.33333vw !important; } }

.u-pd--r-xs {
  padding-right: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--r-xs {
      padding-right: 10px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--r-xs {
      padding-right: 2.66667vw !important; } }

.u-pd--b-0 {
  padding-bottom: 0 !important; }

.u-pd--b-xl {
  padding-bottom: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--b-xl {
      padding-bottom: 50px !important; } }

.u-pd--b-lg {
  padding-bottom: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--b-lg {
      padding-bottom: 40px !important; } }

.u-pd--b-md {
  padding-bottom: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--b-md {
      padding-bottom: 30px !important; } }

.u-pd--b-sm {
  padding-bottom: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--b-sm {
      padding-bottom: 20px !important; } }

.u-pd--b-xs {
  padding-bottom: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--b-xs {
      padding-bottom: 10px !important; } }

.u-pd--l-0 {
  padding-left: 0 !important; }

.u-pd--l-xl {
  padding-left: 130px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--l-xl {
      padding-left: 50px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--l-xl {
      padding-left: 13.33333vw !important; } }

.u-pd--l-lg {
  padding-left: 80px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--l-lg {
      padding-left: 40px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--l-lg {
      padding-left: 10.66667vw !important; } }

.u-pd--l-md {
  padding-left: 60px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--l-md {
      padding-left: 30px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--l-md {
      padding-left: 8vw !important; } }

.u-pd--l-sm {
  padding-left: 40px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--l-sm {
      padding-left: 20px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--l-sm {
      padding-left: 5.33333vw !important; } }

.u-pd--l-xs {
  padding-left: 20px !important; }
  @media screen and (max-width: 750px) {
    .u-pd--l-xs {
      padding-left: 10px !important; } }
  @media screen and (max-width: 340px) and (orientation: portrait) {
    .u-pd--l-xs {
      padding-left: 2.66667vw !important; } }

.u-relative {
  position: relative; }

/* text
----------------------------------------------------------------- */
.u-text--size-sm {
  font-size: 0.8em; }

.u-text--deco-none {
  text-decoration: none; }

.u-text--link {
  color: #004EA2; }
